import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Kiji from "@components/kiji-link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <section className='honbun'>
      <p><strong parentName="p">{`補足：`}</strong></p>
      <p>{`「同じ曲調」というのは、言い換えると「同じ楽器の構成」ということです。`}<br parentName="p"></br>{`
`}{`例えば、この見本曲のような「ドラム、ベース、ギター、キーボード、ボーカル」という構成の曲なら、同じ「ドラム、ベース、ギター、キーボード、ボーカル」という構成の曲をリファレンスに選びます。`}<br parentName="p"></br>{`
`}{`こうすることで、主要な楽器は確実にリファレンスと共通するので、それをお手本にしながら作業を進めることができます。`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      